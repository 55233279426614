import { graphql, Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Callout from '../components/Callout';
import Carousel from '../components/Carousel';
import { Column, Container, Row } from '../components/Grid';
import Hero from '../components/Hero';
import Image from '../components/Image';
import Layout from '../components/Layout';
import Resource from '../components/Resource';
import Section from '../components/Section';
import Subfooter from '../components/Subfooter';
import SurveyCallout from '../components/SurveyCallout';
import { MEDIA_TYPE } from '../utils/constants';
import { getKeyedResources } from '../utils/resource-helper';

const textOverlay = (
  <div className="home__text_overlay_container">
    <Container fullWidth>
      <div className="home__text_overlay_logo">
        <Image filename="logo-discover-data.svg" />
      </div>
      <div className="home__text_overlay_info">
        Decoding a future where everyone counts
      </div>
    </Container>
  </div>
);

const subfooterContent = (
  <span>
    Grades in school are a kind of data, which can be collected and analyzed to
    learn more about a student's educational journey.
  </span>
);

const profiles = [
  {
    image: 'profile-data-science@2x.jpg',
    header: 'Meet Ed, Director of Data Science',
    description:
      'Learn how data can be used to create immersive worlds that accurately mirror reality, such as in video games. Ed and his team use combinations of data sets to uncover valuable information.',
    path: '/career-profiles/director-of-data-science/',
  },
  {
    image: 'profile-client-solutions-executive@2x.jpg',
    header: 'Meet Janay, Client Solutions Executive',
    description:
      'Discover the ways businesses and other organizations receive the specific data they desire based on their individual goals. Janay supports her clients’ diverse needs with curated data insights.',
    path: '/career-profiles/client-solutions-executive/',
  },
  {
    image: 'profile-data-engineer@2x.jpg',
    header: 'Meet Kweku, Data Engineer',
    description:
      'Go inside the mechanics of how data is stored and used by applications to understand how it can be harnessed by people. Kweku specializes in integrating data and safely moving it around.',
    path: '/career-profiles/data-engineer/',
  },
  {
    image: 'profile-client-operations-manager@2x.jpg',
    header: 'Meet My, Client Operations Manager',
    description:
      'Explore the team dynamics necessary for successfully utilizing data. My supports clients and every member of her team with her ability to visualize data and create processes to solve problems.',
    path: '/career-profiles/client-operations-manager/',
  },
  {
    image: 'profile-director-of-client-solutions@2x.jpg',
    header: 'Meet Andrew, Director of Client Solutions',
    description:
      'Find out how TV networks profile their audience using data points like age, gender, personal interests, and more. Andrew excels at finding ways to utilize data to support his clients’ unique needs.',
    path: '/career-profiles/director-of-client-solutions/',
  },
  {
    image: 'profile-account-manager@2x.jpg',
    header: 'Meet Danielle, Account Manager',
    description:
      'See how major sports leagues rely on complex data to create a better experience for fans. Danielle supports the “end-to-end” process of data analysis and management for her clients.',
    path: '/career-profiles/account-manager/',
  },
];

const IndexPage = ({ data }) => {
  const { educators, students, community } = getKeyedResources(data);
  const [profileToDisplay, setProfileToDisplay] = useState(null);

  useEffect(() => {
    setProfileToDisplay(profiles[Math.floor(Math.random() * profiles.length)]);
  }, []);

  return (
    <Layout title="Home">
      <Hero
        guidSrc="88923948-4d1b-4aa4-98c5-1acc150eb703"
        mediaType={MEDIA_TYPE.MP4_VIDEO}
        textOverlay={textOverlay}
        className={'hero--video'}
      />
      <Section className="home__callout-section section--gray">
        <Container fullWidth>
          <Row className="home__callout-row">
            <Column largeOffset={1} large={10}>
              <Callout
                to="/career-profiles/qa-with-data-experts/"
                className="home__callout"
                containerClassName="home__callout-container"
              >
                <h3>
                  Ask a question and get an answer from a real-world data expert
                </h3>
              </Callout>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className="section--gray">
        <Container>
          <Row>
            <Column size={12}>
              <h2>Dive into students’ world through data</h2>
            </Column>
          </Row>
          <Row>
            <Column size={4}>
              <Resource
                title={educators.title}
                description={educators.description}
                img={educators.img}
                actions={educators.actions}
                className={educators.className}
                linkResource
              />
            </Column>
            <Column size={4}>
              <Resource
                title={students.title}
                description={students.description}
                img={students.img}
                actions={students.actions}
                className={students.className}
                linkResource
              />
            </Column>
            <Column size={4}>
              <Resource
                title={community.title}
                description={community.description}
                img={community.img}
                actions={community.actions}
                className={community.className}
                linkResource
              />
            </Column>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <Row>
            <Column size={12}>
              <h2>A “data” in the life...</h2>
            </Column>
          </Row>
          <Row>
            {profileToDisplay && (
              <>
                <Column size={3} className="home__profile-column">
                  <div className="home__profile-container">
                    <Image filename={profileToDisplay.image} />
                  </div>
                </Column>
                <Column size={7}>
                  <h3>{profileToDisplay.header}</h3>
                  <p>{profileToDisplay.description}</p>
                  <Link to={profileToDisplay.path} className="text-bold">
                    Learn more about this career
                  </Link>
                </Column>
              </>
            )}
          </Row>
        </Container>
      </Section>
      <Section className="home__data-doorways-section">
        <Container>
          <Row>
            <Column size={12}>
              <h2>Data doorways</h2>
              <p className="text-sans-serif">
                From media to equality, timely topics in the form of data can
                help start meaningful conversations and deepen investigations.
              </p>
            </Column>
          </Row>
          <Row className="home__data-doorways-carousel-row">
            <Column size={6}>
              <Carousel
                settings={{ dots: true }}
                slides={[
                  {
                    image: 'hmpg-carousel1@2x.jpg',
                    title: 'Demographic',
                    text:
                      'Statistical data related to human populations (such as age or income).',
                  },
                  {
                    image: 'hmpg-carousel2@2x.jpg',
                    title: 'Gender gap',
                    text:
                      'The difference between men and women, in regard to economics, education, health, or politics.',
                  },
                  {
                    image: 'hmpg-carousel3@2x.jpg',
                    title: 'Viewership',
                    text:
                      'The number of people who watch a particular television or streaming program or channel.',
                  },
                  {
                    image: 'hmpg-carousel4@2x.jpg',
                    title: 'Cost-benefit analysis',
                    text:
                      'Estimation of the strengths and weaknesses of an approach, decision, process, or program.',
                  },
                  {
                    image: 'hmpg-carousel5@2x.jpg',
                    title: 'Fan base',
                    text:
                      'A group of supporters for a particular sport or team.',
                  },
                ]}
              />
            </Column>
            <Column size={6} className="home__data-doorways-text-container">
              <h3>What is data?</h3>
              <p className="text-sans-serif">
                Data is a way of looking at the world. Change how your students
                view their everyday lives, our planet, and the future by tapping
                into the infinite potential of data.
              </p>
              <Link to="/community" className="text-sans-serif text-bold">
                Learn more about how to get involved with data
              </Link>
            </Column>
          </Row>
        </Container>
      </Section>
      <SurveyCallout />
      <Section className="pt-0 pb-0">
        <Subfooter
          img="footer-quote-career@2x.jpg"
          content={subfooterContent}
          icon="icon-graduate-hat.svg"
          home
        />
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query HomeResources {
    allResourcesJson(filter: { pages: { in: "home" } }) {
      edges {
        node {
          slug
          tophat
          img
          icon
          subject
          title
          audience
          duration
          description
          actions {
            label
            metadata
            link
            module
            moduleName
            download
            noIcon
            asLink
          }
          horizontal
          className
          pages
        }
      }
    }
  }
`;

export default IndexPage;
